import React from 'react'
import { css } from '@emotion/core'

import { Colors, fontSizes } from '../lib/theme'

import { Box, Flex } from './Grid'

import Github from '../svgs/github-icon.svg'
import Twitter from '../svgs/twitter-icon.svg'

const styles = {
  container: css({
    color: Colors.WHITE,
    fontSize: fontSizes.S16,
    paddingBottom: 21,
    paddingTop: 21,
  }),
  icon: css({
    padding: 5,
    maxHeight: 38,
    maxWidth: 38,
    transition: 'all 400ms ease-in-out',
    ':hover path': {
      fill: Colors.GRAY_MEDIUM
    }
  }),
  link: css({
    color: Colors.WHITE,
    textDecoration: 'none',
  }),
  visibilityHidden: css({
    clip: 'rect(1px, 1px, 1px, 1px)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    textTransform: 'none',
    width: 1,
  })
}

const Footer = () => (
  <Flex
    as="footer"
    justifyContent="space-between"
    css={styles.container}
    px={40}>
    <Box>
      © {new Date().getFullYear()}, <a css={styles.link} href="http://gastonfig.com">Gaston Figueroa</a>
    </Box>
    <Box>
      <a css={styles.link} href="https://twitter.com/gastonfig" target="_blank" rel="noopener noreferrer">
        <span css={styles.visibilityHidden}>@gastonfig on Twitter</span>
          <Twitter css={styles.icon}/>

      </a>
      <a css={styles.link} href="https://github.com/gastonfig" target="_blank" rel="noopener noreferrer">
        <span css={styles.visibilityHidden}>@gastonfig on Github</span>
          <Github css={styles.icon}/>

      </a>
    </Box>
  </Flex>
)

export default Footer
