import React from 'react'
import { Flex as EmotionFlex } from '@rebass/grid/emotion'

const gutters = '-20px'

export const Flex = props =>
  React.createElement(EmotionFlex, {
    flexWrap: 'wrap',
    mx: gutters,
    ...props,
  })
