import React from 'react'
import { Box as EmotionBox } from '@rebass/grid/emotion'

const gutters = '20px'

export const Box = props =>
  React.createElement(EmotionBox, {
    px: gutters,
    ...props,
  })
