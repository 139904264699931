import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { css } from '@emotion/core'

import { Colors, fontSizes, fontWeights } from '../lib/theme'

const styles = {
  heading: css({
    fontSize: fontSizes.S21,
    fontWeight: fontWeights.SEMI_BOLD,
    margin: 0,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
  }),
  link: css({
    color: Colors.WHITE,
    textDecoration: 'none',
  }),
}

const Header = ({ siteTitle }) => (
  <header>
    <h1 css={styles.heading}>
      <Link css={styles.link} to="/">
        gf
      </Link>
    </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
