export const fontSizes = {
  S16: 16,
  S21: 21,
  S24: 24,
  S33: 33,
  S36: 36,
  S39: 39,
  S42: 42,
  S54: 54,
  S69: 69,
}

export const fontWeights = {
  REGULAR: 400,
  SEMI_BOLD: 600,
  BOLD: 700,
  HEAVY: 800,
}
