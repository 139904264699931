import { css as emotionCss } from '@emotion/core'
import facepaint from 'facepaint'

export const breakpoints = [40.625, 60, 75, 87.5, 100, 120]

export const theme = {
  space: [0, 6, 12, 18, 24],
}

const mq = facepaint([
  `@media(min-width: ${breakpoints[0]}em)`,
  `@media(min-width: ${breakpoints[1]}em)`,
  `@media(min-width: ${breakpoints[2]}em)`,
  `@media(min-width: ${breakpoints[3]}em)`,
  `@media(min-width: ${breakpoints[4]}em)`,
])

export const css = (...rules) => emotionCss(mq(rules))
